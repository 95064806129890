<div class="row flex-column flex-md-row  " [formGroup]="form">
    <div class="col-12 col-md-12  col-lg-8">
        <div class="row">
            <div class="col-12 col-lg-4">
                <nb-select multiple formControlName="buildId"
                    *ngIf=" filterItems?.builds !=null ||filterItems?.builds!=undefined" placeholder="Yapı Seçiniz"
                    fullWidth size="giant" class="my-2" shape="semi-round">

                    <nb-select-label>
                        {{helperService.customSelectLabel(filterItems?.builds,buildId.value,'id','name','YapıSeçiniz')
                        }}
                    </nb-select-label>
                    <nb-option [value]="null">Seçimi Temizle</nb-option>
                    <nb-option class="AllOption m-0 p-0" [value]="'All'">
                        <button class="w-100 multiple nb-transition AllButton text-left"
                            (click)="selectAllBuildButton()">
                            {{isSlectedBuilding('All')?'Tüm Seçimleri Kaldır':'Tümünü Seç'}}</button>
                    </nb-option>
                    <!-- <nb-option class="AllOption" [value]="'All'" (selectionChange)="selectionChangeBuilding($event)">
                        {{isSlectedBuilding('All')?'Tüm Seçimleri Kaldır':'Tümünü Seç'}}</nb-option> -->
                    <nb-option [value]="item.id" *ngFor="let item of filterItems?.builds ">{{item.name}}
                    </nb-option>

                </nb-select>
                <!-- <ng-select [items]="storeService.buildingList" bindLabel="name" bindValue="id"></ng-select> -->
            </div>
            <div class="col-12 col-lg-4">


                <nb-select multiple formControlName="storeId" placeholder="Mağaza Seçiniz" fullWidth size="giant"
                    class="my-2" shape="semi-round">
                    <nb-select-label>
                        {{helperService.customSelectLabel(stores,storeId.value,'id','name','Mağaza Seçiniz') }}
                    </nb-select-label>
                    <nb-option [value]="null">Seçimi Temizle</nb-option>
                    <nb-option class="AllOption m-0 p-0" [value]="'All'">
                        <button class="w-100 multiple nb-transition AllButton text-left"
                            (click)="selectAllStoreButton()">
                            {{isSlectedStore('All')?'Tüm Seçimleri Kaldır':'Tümünü Seç'}}</button>
                    </nb-option>
                    <nb-option [value]="item.id" *ngFor="let item of stores ">{{item.name}}
                    </nb-option>
                </nb-select>
                <!-- <ng-select [items]="storeService.buildingList" bindLabel="name" bindValue="id"></ng-select> -->

            </div>

            <div class="col-12 col-lg-4">
                <nb-select
                  fullWidth
                  style="width: 100%"
                  multiple
                  formControlName="serviceTypes"
                  placeholder="Servis Tipi"
                  size="giant"
                  class="my-2 w-100"
                  shape="semi-round"
                >
                  <nb-select-label>
                    {{
                      mockService.getServiceTypes()
                        | customSelectLabel: serviceTypes.value:"value":"text":"Servis Tipi"
                    }}
                  </nb-select-label>
                  <nb-option [value]="null">Seçimi Temizle</nb-option>
                  <nb-option class="AllOption m-0 p-0" [value]="'All'">
                    <button
                      class="w-100 multiple nb-transition AllButton text-left"
                      (click)="selectAllServiceTypesButton()"
                    >
                      {{
                        isSlectedServiceTypes("All") ? "Tüm Seçimleri Kaldır" : "Tümünü Seç"
                      }}
                    </button>
                  </nb-option>
                  <nb-option
                    [value]="item.value"
                    *ngFor="let item of mockService.getServiceTypes()"
                    >{{ item.text }}
                  </nb-option>
                </nb-select>
              </div>
        </div>
    </div>
    <div class="col-12 col-md-12  col-lg-4">
        <kerzz-button-group class="my-3 d-flex " formControlName="range"></kerzz-button-group>
    </div>

    <div class="col-12" style="padding: 0!important;">
        <div class="row justify-content-between  " style="margin-bottom: 40px;">
            <div class="col-xs-12 col-xl-6 col-lg-6 my-3">
                <div class="shadow box pb-4" style="padding-top: 20px;padding-left: 0px;">
                    <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                        [legend]="barChartLegend"[colors]="colors" [chartType]="barChartType"></canvas>
                </div>
            </div>
            <div class="col-xs-12 col-xl-6 col-lg-6 my-3 ">
                <div class=" shadow box pb-4" style="padding-top: 20px;padding-left: 0px;">

                    <canvas baseChart [labels]="pieChartLabels" [datasets]="pieChartData"
                        [options]="pieChartOptions" [colors]="colors" [chartType]="doughnutChartType"></canvas>
                </div>
            </div>
        </div>
        <div class="row" style="margin-bottom: 40px;">
            <div class="col-12">
                <div class="row justify-content-between">
                    <div class="col-xs-12 col-xl-4 col-lg-4 my-3">
                        <nb-card style="width: 100%;height: 13.5rem;" class="shadow">
                            <nb-card-header style="border-bottom: unset;">
                                Toplam Satış
                            </nb-card-header>
                            <nb-card-body
                                style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
                                <h2> {{netTotals | currency}} </h2>
                            </nb-card-body>
                        </nb-card>
                    </div>
                    <!-- <div class="col-xs-12 col-xl-4 col-lg-4 my-3">
                        <div class="col-12 shadow box" style="padding-top: 20px;padding-left: 0px;">
                            <div class="row mx-auto my-6">
                                <div class="row" style="padding-left: 40px;">
                                    <div class="col-6">
                                        <h5>Toplam Satış</h5>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <h2>
                                        772,444.00₺
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-xs-12 col-xl-4 col-lg-4 my-sm-3">
                        <nb-card style="width: 100%;height: 13.5rem;" class="shadow">
                            <nb-card-header style="border-bottom: unset;">
                                Adisyon Sayısı
                            </nb-card-header>
                            <nb-card-body
                                style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
                                <h2> {{countFolios}} </h2>
                            </nb-card-body>
                        </nb-card>
                        <!--                         
                        <div class="col-12 shadow box" style="padding-top: 20px;padding-left: 0px;">
                            <div class="row" style="padding-left: 40px;margin-bottom: 70px;">
                                <div class="col-6">
                                    <h5>Toplam Kişi</h5>
                                </div>
                            </div>
                            <div class="row mx-auto">
                                <div class="col-12">
                                    <h2>
                                        1,444
                                    </h2>
                                </div>
                            </div> 
                    </div>-->
                    </div>
                    <div class="col-xs-12 col-xl-4 col-lg-4 my-3">
                        <nb-card style="width: 100%;height: 13.5rem;" class="shadow">
                            <nb-card-header style="border-bottom: unset;">
                                Toplam Kişi
                            </nb-card-header>
                            <nb-card-body
                                style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
                                <h2>
                                    {{countPaxes}}
                                </h2>
                            </nb-card-body>
                        </nb-card>
                        <!-- <div class="col-12 shadow box" style="padding-top: 20px;padding-left: 0px;">
                            <div class="row" style="padding-left: 40px;margin-bottom: 70px;">
                                <div class="col-6">
                                    <h5>Adisyon Sayısı</h5>
                                </div>
                            </div>
                            <div class="row mx-auto">
                                <div class="col-12">
                                    <h2>
                                        1,244
                                    </h2>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12">
            <h6>Bugün Toplam Dağılımları</h6>
        </div>

        <div class="d-flex my-2">
            <button class="k-button" (click)="exportExcel(table)" nbButton>
                <span class="mr-2">
                    <img src="https://img.icons8.com/fluent-systems-regular/48/000000/ms-excel.png" width="18" />
                </span>
                Excel İndir
            </button>
            <button class="ml-2 k-button" (click)="exportPDF()" nbButton>
                <span class="material-icons-outlined mr-2" style="font-size: 18px;">
                    picture_as_pdf
                </span>
                PDF İndir
            </button>
        </div>
        <div class="col-12  shadow table-style">
            <div style="overflow-x: auto;">
                <table class="table mb-0" #table>
                    <thead style="max-height: 50px;">
                        <tr>
                            <th>Yapı Adı</th>
                            <th>Mağaza Adı</th>
                            <th>Toplam Satış</th>
                            <th>T. Adisyon Sayısı</th>
                            <th>T. Kişi Sayısı</th>
                        </tr>

                        <tr [formGroup]="filterForm">
                            <th scope="col">
                                <nb-form-field>
                                    <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                                    <input fieldSize="small" type="text" nbInput formControlName="buildingName">

                                </nb-form-field>
                            </th>
                            <th scope="col">
                                <nb-form-field>
                                    <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                                    <input fieldSize="small" type="text" nbInput formControlName="storeName">
                                </nb-form-field>
                            </th>
                            <th scope="col">
                                <nb-form-field>
                                    <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                                    <input fieldSize="small" type="text" nbInput formControlName="grandTotal">
                                </nb-form-field>
                            </th>
                            <th scope="col">
                                <nb-form-field>
                                    <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                                    <input fieldSize="small" type="text" nbInput formControlName="countFolio">
                                </nb-form-field>
                            </th>
                            <th scope="col">
                                <nb-form-field>
                                    <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                                    <input fieldSize="small" type="text" nbInput formControlName="countPax">
                                </nb-form-field>
                            </th>

                        </tr>
                    </thead>

                    <tbody>
                        <!--
      totalSale: 165.89,
      totalAdisyon: 33,
      totalPersonCount: 34,
                    -->
                        <tr *ngFor="let item of dataSource">
                            <td>
                                {{item.buildingName}}
                            </td>
                            <td routerLink="/layout/sales-detail-report" [queryParams]="{storeId: item.storeId, startDate: getStartDate(), endDate: getEndDate()}">{{item.storeName}}</td>
                            <td class="text-right">{{item.grandTotal| currency}}</td>
                            <td class="text-right">{{item.countFolio}}</td>
                            <td class="text-right">{{item.countPax}}</td>
                        </tr>


                    </tbody>
                </table>
            </div>
            <!-- <div class="total px-2">
                    
                            <div>Total: 12</div>
                    
                        </div> -->


        </div>


    </div>
</div>
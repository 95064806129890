import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { resultFilterValidator } from 'src/app/components/report-operations/reportValidators';
import { ReportSearch } from 'src/app/models/report.model';
import { Building, LinkedStore, Store } from 'src/app/models/store-model';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService } from 'src/app/services/helper.service';
import { MockService } from 'src/app/services/mock.service';
import { ReportService } from 'src/app/services/report.service';
import { StoreService } from 'src/app/services/store.service';
import { UserService } from 'src/app/services/user.service';
import { KzFilterYearComponent } from './components/kz-filter-year/kz-filter-year.component';
import moment from 'moment';

@Component({
  selector: 'kerzz-report-filter',
  templateUrl: './kerzz-report-filter.component.html',
  styleUrls: ['./kerzz-report-filter.component.scss'],
})
export class KerzzReportFilterComponent implements OnInit, AfterViewInit {
  @ContentChild(KzFilterYearComponent, { static: false })
  kzFilterYearComponent!: KzFilterYearComponent;
  @Output() onSearched: EventEmitter<ReportSearch> =
    new EventEmitter<ReportSearch>();

  @Input() isHaveServiceType = true;
  @Input() endDate = true;
  searchKey: FormControl = new FormControl();
  form: FormGroup;
  get start(): FormControl {
    return this.form.get('start') as FormControl;
  }
  get end(): FormControl {
    return this.form.get('end') as FormControl;
  }
  get buildId(): FormControl {
    return this.form.get('buildId') as FormControl;
  }
  get storeId(): FormControl {
    return this.form.get('storeId') as FormControl;
  }
  get serviceTypes(): FormControl {
    return this.form.get('serviceTypes') as FormControl;
  }
  linkedStores: LinkedStore[] = [];

  filterItems: { stores?: Store[]; builds?: Building[] } = {};
  stores: Store[] = [];
  isFirstConfig: boolean = true;
  @Input() set initialValues(e: any) {
    console.log('initialValues', e);
    if (e) {
      setTimeout(() => {
        this.form.patchValue({
          storeId: [e.storeId],
          start: new Date(e.startDate),
          end: new Date(e.endDate),
        });
        this.onMainFilter()
      }, 500);
    }
  }
  constructor(
    private fb: FormBuilder,
    public mockService: MockService,
    public storeService: StoreService,
    public helperService: HelperService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private currencyPipe: CurrencyPipe,
    private userService: UserService,
    private reportService: ReportService,
    private globalService: GlobalService
  ) {}
  ngAfterViewInit(): void {
    if (this.kzFilterYearComponent) {
      //  console.log('End Validator delted');
      this.end.setValidators([]);
      this.end.updateValueAndValidity();
      this.start.setValue(new Date().getFullYear());
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.userService.getLinkedStores().then((res) => {
      this.linkedStores = res;
      this.setFilterItems();
    });
  }

  initForm(): void {
 const now: Date = new Date();
 this.form = this.fb.group(
   {
     start: [
       moment(HelperService.getStartOfWeek(now).setHours(0, 0, 0)).toDate(),
       Validators.required,
     ],
     end: [
       moment(HelperService.getEndOfWeek(now).setHours(23, 59, 59)).toDate(),
       Validators.required,
     ],
     buildId: [null, [Validators.required]],
     storeId: [{ value: null, disabled: false }, [Validators.required]],
     serviceTypes: [, this.isHaveServiceType ? Validators.required : null],
   },
   { validators: resultFilterValidator }
 );
    this.buildId.valueChanges.subscribe((value: string[]) => {
      this.setStores(value);
    });
    this.storeId.valueChanges.subscribe((value: string[]) => {
      console.log('storeId', value);
    });
  }
  setStores(value: string[]): void {
    this.storeId.reset([], { emitEvent: false });

    if (HelperService.isNullOrUndefined(value) || value.length === 0) {
      this.storeId.disable({ emitEvent: false });
    } else if (value.length === 1 && value.some((el) => el === 'All')) {
      this.storeId.disable({ emitEvent: false });
    } else {
      this.stores = [];
      value.forEach((el) => {
        this.stores = [
          ...this.stores,
          ...this.filterItems.stores.filter((f) => f.buildingId == el),
        ];
        // this.stores.forEach(el => {
        //   el['group'] = 'Tümü';
        // })
      });
      this.storeId.enable({ emitEvent: false });
      if (this.isFirstConfig) {
        setTimeout(() => {
          this.isFirstConfig = false;
          const stores: string[] = this.selectAllStore(this.stores, 'id');
          //  console.log('Stores:', stores);

          if (this.reportService.filters.storeId) {
            this.storeId.setValue(this.reportService.filters.storeId);
          } else {
            this.storeId.setValue(stores);
          }
          // this.onMainFilter();
        }, 100);
      }
    }
  }

  setFilterItems(): void {
    this.filterItems.stores = this.storeService.storeList.filter((el) =>
      this.linkedStores.some((s) => s.storeId === el.id)
    );
    this.filterItems.builds = this.storeService.buildingList.filter((el) =>
      this.filterItems.stores.some((s) => s.buildingId === el.id)
    );
    // console.log(this.filterItems);

    this.firstConfigs();
  }
  firstConfigs(): void {
    const builds: string[] = this.selectAllBuilding(
      this.filterItems.builds,
      'id'
    );

    const serviceTypes: string[] = this.selectAllServiceTypes(
      this.mockService.getServiceTypes(),
      'value'
    );

    if (this.reportService.filters.buildId) {
      this.buildId.setValue(this.reportService.filters.buildId);
    } else {
      this.buildId.setValue(builds);
    }

    if (this.reportService.filters.serviceTypes) {
      this.serviceTypes.setValue(this.reportService.filters.serviceTypes);
    } else {
      this.serviceTypes.setValue(serviceTypes);
    }
  }
  //#region BildingSelection
  selectAllBuilding(buildingList: Building[], key: string): string[] {
    return buildingList.map((el) => el[key]);
  }
  isSlectedBuilding(value: string): boolean {
    return (
      this.buildId?.value?.filter((el) => el != value).length ===
      this.filterItems.builds?.length
    );
  }
  selectAllBuildButton(): void {
    let values: string[] = [];
    if (!this.isSlectedBuilding('All')) {
      values = this.selectAllBuilding(this.filterItems.builds, 'id');
    }
    this.buildId.setValue(values, { emitEvent: false });
  }
  //#endregion

  //#region  Store Selection

  selectAllStore(storeList: Store[], key: string): string[] {
    return storeList.map((el) => el[key]);
  }
  isSlectedStore(value: string): boolean {
    return (
      this.storeId?.value?.filter((el) => el !== value).length ===
      this.stores?.length
    );
  }
  selectAllStoreButton(): void {
    let values: string[] = [];
    if (!this.isSlectedStore('All')) {
      values = this.selectAllStore(this.stores, 'id');
    }
    this.storeId.setValue(values, { emitEvent: false });
  }
  //#endregion

  //#region  Service Type Selection

  selectAllServiceTypes(storeList: any[], key: string): string[] {
    return this.mockService.getServiceTypes().map((el) => el[key]);
  }
  isSlectedServiceTypes(value: string): boolean {
    return (
      this.serviceTypes?.value?.filter((el) => el !== value).length ===
      this.mockService.getServiceTypes()?.length
    );
  }
  selectAllServiceTypesButton(): void {
    let values: string[] = [];
    if (!this.isSlectedServiceTypes('All')) {
      values = this.selectAllServiceTypes(
        this.mockService.getServiceTypes(),
        'value'
      );
    }
    this.serviceTypes.setValue(values, { emitEvent: false });
  }
  //#endregion
  /**
   * OnFilter
   */
  onMainFilter(): void {
    const params: ReportSearch = this.getParams();

    // params.startDate.setUTCDate(params.startDate.getDate());
    // params.startDate.setUTCHours(0, 0, 0, 0 + 3);

    params.startDate = moment(params.startDate).format();

    // params.endDate.setUTCDate(params.endDate.getDate());
    // params.endDate.setUTCHours(23, 59, 59, 999 + 3);
    params.endDate = moment(params.endDate).format();

    this.reportService.filters = Object.assign(this.form.value);
    this.onSearched.emit(params);
  }
  getParams(): ReportSearch {
    const params: ReportSearch = {
      startDate: new Date(this.start.value.toString()),
      endDate: new Date(this.end.value.toString()),
      serviceTypes: this.serviceTypes.value.filter((el) => el !== 'All'),
      storeIdList: this.storeId.value.filter((el) => el !== 'All'),
    };
    if (!this.isHaveServiceType) {
      params.serviceTypes = undefined;
    }
    if (this.kzFilterYearComponent) {
      // temprorary date
      const sDate: Date = new Date();
      let eDate: Date = new Date();

      // sete year of user selected
      sDate.setFullYear(this.start.value);
      eDate.setFullYear(this.start.value);

      // set starto of day
      // sDate.setHours(12, 0, 0, 0);
      // eDate.setHours(12, 23, 59, 999);

      sDate.setMonth(0);
      eDate.setMonth(11);
      sDate.setDate(1);
      eDate = new Date(eDate.getFullYear(), eDate.getMonth() + 1, 0);

      // temprorary date assign to params startDate
      params.startDate = sDate;

      // temprorary date assign to params endDate
      params.endDate = eDate;

      return params;
    } else {
      // params.endDate.setHours(12, 59, 59, 999);
      // params.startDate.setHours(12, 0, 0, 0);
    }

    return params;
  }
  ignorClick(e) {
    e.stopPropagation();
    e.preventDefault();
  }
  unSelectAllStore(key) {
    this.form.get(key).reset();
  }
}
